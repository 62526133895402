import {
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	InputNumber,
	Row,
	Switch,
	Table,
	Upload,
	message,
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import TRPCLayout from '../../../utils/TRPCLayout';
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { TrpcBase } from '../../../utils/trpc';
import {
	constantCheckIn,
	constantLease,
	constantMonthlyStatement,
} from '../edit/categorySelectOption';
import { MonthTimePicker } from '../overview/MonthTimePicker';
import { useApartmentList } from '../overview/useApartmentList';
import { globalAntdPagination } from '../util/globalAntdPagination';
import UpdateRemark from './UpdateRemark';
import { csvToArray } from './csvToArray';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Shanghai');
const now = dayjs();

export default () => {
	const [form] = Form.useForm();
	const pickTime = Form.useWatch('time', form);
	const isExcel = Form.useWatch('isExcel', form);
	const isTipsRentItem = Form.useWatch('isTipsRentItem', form);
	const isTransfer = Form.useWatch('isTransfer', form);
	const money = Form.useWatch('money', form);
	const column = Form.useWatch('column', form);
	const isEmptyRemark = Form.useWatch('isEmptyRemark', form);
	const tradeTarget = Form.useWatch('tradeTarget', form) as string;
	const commodity = Form.useWatch('commodity', form) as string;
	const remark = Form.useWatch('remark', form) as string;
	const transactionOrderNumber = Form.useWatch(
		'transactionOrderNumber',
		form,
	) as string;
	const merchantNumber = Form.useWatch('merchantNumber', form) as string;
	const isSecondWallet = Form.useWatch('isSecondWallet', form) as boolean;

	const dataApi = TrpcBase.apartment.ListWechatBill.useQuery({
		page: 0,
		startTime: pickTime && startOfMonth(pickTime).valueOf(),
		endTime: pickTime && endOfMonth(pickTime).valueOf(),
		tradeTarget: tradeTarget,
		commodity: commodity,
		remark: remark,
		isTransfer: isTransfer,
		isSecondWallet: isSecondWallet,
		transactionOrderNumber: transactionOrderNumber,
		merchantNumber: merchantNumber,
		money: money,
		isEmptyRemark: isEmptyRemark,
	});
	const addApi = TrpcBase.apartment.CreateWechatBill.useMutation();

	const rentApi = useApartmentList({
		category: [constantLease, constantCheckIn, constantMonthlyStatement],
		startTime: now && startOfMonth(now.subtract(3, 'month')).valueOf(),
		endTime: now && endOfMonth(now).valueOf(),
	});
	const [previewExcel, setPreviewExcel] = useState<any[]>([]);

	return (
		<TRPCLayout app="apartment">
			<Card>
				<Form
					form={form}
					layout="horizontal"
					initialValues={{ isTipsRentItem: false }}
				>
					<Row>
						<Col span={12}>
							<MonthTimePicker />
						</Col>
						<Col span={8}>
							<Form.Item label="是否excel" name="isExcel">
								<Switch />
							</Form.Item>
						</Col>

						<Col span={4}>
							<Upload
								multiple={false}
								maxCount={1}
								showUploadList={false}
								beforeUpload={(file) => {
									return false;
								}}
								onChange={async (value) => {
									const currentFile = value?.file;

									const reader = new FileReader();

									// @ts-expect-error
									reader.readAsText(currentFile);

									reader.onload = async () => {
										const text = reader.result as string;
										if (!text) {
											throw new Error('no buffer');
										}

										const allTextLines = csvToArray(text)
											.filter((a) =>
												/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(
													a?.[0] ?? '',
												),
											)
											.map((a) => {
												// amount
												if (a[5]) {
													a[5] = a[5]?.replaceAll(/¥/g, '');
												}

												// commodity
												if (a[3]) {
													a[3] = a[3]?.replaceAll(/\"/g, '');
												}

												if (a[5] === '支出') {
													console.log(a);
												}

												// 备注
												if (a[10]) {
													a[10] = a[10]?.replaceAll(/\"/g, '');
												}

												return a;
											});
										console.log(allTextLines);

										const res = await addApi.mutateAsync(allTextLines);
										if (res.code === 0) {
											message.success('导入成功');
										} else {
											message.error('导入失败');
											console.log(res);
										}

										dataApi.refetch();
									};
								}}
							>
								<Button icon={<UploadOutlined />}>导入</Button>
							</Upload>
						</Col>
					</Row>

					<div className="flex flex-row gap-2 flex-wrap">
						<Form.Item label="tradeTarget" name="tradeTarget">
							<Input />
						</Form.Item>
						<Form.Item label="commodity" name="commodity">
							<Input />
						</Form.Item>
						<Form.Item label="remark" name="remark">
							<Input />
						</Form.Item>
						<Form.Item
							label="transactionOrderNumber"
							name="transactionOrderNumber"
						>
							<Input />
						</Form.Item>
						<Form.Item label="merchantNumber" name="merchantNumber">
							<Input />
						</Form.Item>
						<Form.Item label="amount" name="money">
							<InputNumber />
						</Form.Item>
					</div>

					<div className="flex flex-row gap-2 flex-wrap">
						<Form.Item label="是否空备注" name="isEmptyRemark">
							<Switch />
						</Form.Item>
						<Form.Item label="是否转账" name="isTransfer">
							<Switch />
						</Form.Item>
						<Form.Item label="是否零钱通" name="isSecondWallet">
							<Switch />
						</Form.Item>
						<Form.Item label="是否分析单" name="isTipsRentItem">
							<Switch />
						</Form.Item>

						<Form.Item label="显示" name="column" className="overflow-y-auto">
							<Checkbox.Group
								options={[
									{ label: '类型', value: 'expensesReceipt' },
									{ label: 'merchantNumber', value: 'merchantNumber' },
									{ label: 'currentStatus', value: 'currentStatus' },
								]}
							/>
						</Form.Item>
					</div>
				</Form>

				{!isExcel && (
					<div>
						<Table
							rowKey={'transactionOrderNumber'}
							pagination={{
								...globalAntdPagination,
								async onChange(page, pageSize) {
									await dataApi.refetch();
								},
							}}
							className="overflow-y-auto"
							scroll={{ x: 1500 }}
							columns={[
								{
									key: 'exchangeHour',
									dataIndex: 'exchangeHour',
									title: 'exchangeHour',
									render: (v, record) => {
										return (
											<div key={record.transactionOrderNumber}>
												{dayjs(v).format('YYYY-MM-DD HH:mm')}
											</div>
										);
									},
								},
								{
									key: 'remark',
									dataIndex: 'remark',
									title: 'remark',
									width: 200,
									// sorter: true,
									render: (v, record) => {
										return (
											<UpdateRemark
												key={record.transactionOrderNumber}
												remark={v}
												amount={record.amount}
												transactionOrderNumber={record.transactionOrderNumber}
												leaseData={isTipsRentItem ? rentApi.list : []}
											/>
										);
									},
								},
								{
									key: 'tradeType',
									dataIndex: 'tradeType',
									title: 'tradeType',
								},
								{
									key: 'tradeTarget',
									dataIndex: 'tradeTarget',
									title: 'tradeTarget',
								},
								{
									key: 'commodity',
									dataIndex: 'commodity',
									title: 'commodity',
								},
								{
									key: 'expensesReceipt',
									dataIndex: 'expensesReceipt',
									title: 'expensesReceipt',
									isHaving: () => column?.includes('expensesReceipt'),
								},
								{ key: 'amount', dataIndex: 'amount', title: 'amount' },
								{
									key: 'paymentMode',
									dataIndex: 'paymentMode',
									title: 'paymentMode',
								},
								{
									key: 'currentStatus',
									dataIndex: 'currentStatus',
									title: 'currentStatus',
									isHaving: () => column?.includes('currentStatus'),
								},
								{
									key: 'transactionOrderNumber',
									dataIndex: 'transactionOrderNumber',
									title: 'transactionOrderNumber',
								},
								{
									key: 'merchantNumber',
									dataIndex: 'merchantNumber',
									title: 'merchantNumber',
									isHaving: () => column?.includes('merchantNumber'),
								},

								// { key: 'userId', dataIndex: 'userId', title: 'userId' },
							].filter((i) => {
								return i?.isHaving ? i?.isHaving?.() : true;
							})}
							dataSource={dataApi.data}
						/>
						<div>
							收入转账：
							{
								dataApi.data?.filter(
									(i) =>
										(i.tradeTarget === '刘清洋' ||
											i.tradeTarget === '赖林香') &&
										i.expensesReceipt === '收入' &&
										'已存入零钱' === i.currentStatus,
								).length
							}
						</div>
						<div>
							转入零钱通-来自零钱:
							{
								dataApi.data?.filter(
									(i) => '转入零钱通-来自零钱' === i.tradeType,
								).length
							}
						</div>
					</div>
				)}

				{isExcel && (
					<Card>
						<table
							style={{
								fontFamily: '宋体',
								fontSize: '1.0rem',
								border: '1px #e8e8e8 solid',
								borderCollapse: 'collapse',
							}}
						>
							{dataApi?.data
								?.filter(
									(a) =>
										a.tradeType === '转入零钱通-来自零钱' ||
										a.paymentMode === '零钱通',
								)
								?.map((i) => {
									const cellBorder: React.CSSProperties = {
										border: '1px #e8e8e8 solid',
										borderCollapse: 'collapse',
									};
									return (
										<tr
											key={i.transactionOrderNumber}
											className="grid grid-cols-3"
										>
											<td style={cellBorder}>
												{i?.expensesReceipt === '支出'
													? `${-i.amount}`
													: `${i.amount}`}
											</td>

											<td style={cellBorder}>{`${i.remark} ${
												i?.commodity !== '/' ? i?.commodity : ''
											} ${i?.tradeType !== '/' ? i?.tradeType : ''}`}</td>

											<td style={cellBorder}>
												{dayjs(i?.exchangeHour).format('YYYY年MM月DD日')}
											</td>
										</tr>
									);
								})}
						</table>
					</Card>
				)}
			</Card>
		</TRPCLayout>
	);
};
